<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="e1" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getImplementationError.stepOne
                                            .validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('devices:basicInformations')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="
                                                $t('devices:basicInformations')
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12" lg="12">
                                        <v-textarea
                                            auto-grow
                                            rows="1"
                                            :label="$t('devices:data')"
                                            v-model="
                                                getImplementationModal.data
                                            "
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12" lg="12">
                                        <v-textarea
                                            rows="1"
                                            auto-grow
                                            :label="$t('devices:settings')"
                                            v-model="
                                                getImplementationModal.settings
                                            "
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            editable: true,
            e1: 1,
        }
    },
    computed: {
        ...mapGetters(['getImplementationModal', 'getImplementationError']),
    },
}
</script>
