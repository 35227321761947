<template>
    <v-container>
        <Header
            :registryStatus="getDeviceStatuses"
            :recordIndex="getDeviceDetails.index"
            :registryTitle="getDevicesRegistry.name"
            :registryUrl="'devices'"
            :actualStatus="getDeviceDetails.status"
            @status-changed="changeStatus"
            :avatarData="getDeviceDetails.createdBy"
        />
        <Main :items="items" class="p-relative">
            <v-tab-item
                v-for="item in items"
                :key="item.id"
                slot="tabItems"
                :id="item.id"
            >
                <component :is="item.component"></component>
            </v-tab-item>
            <div slot="information">
                <Information @view-map="openMap" />

                <Modal
                    :title="$t('devices:editionDevice')"
                    :open="open"
                    :height="
                        window.width >= 1200 ? '760' : 'calc( 600px - 110px )'
                    "
                    :width="`${window.width - 180}`"
                    v-on:close="closeModal()"
                >
                    <AEContent
                        :action="'edit'"
                        @view-map="openMap"
                        slot="AEContent"
                    />
                    <Buttons
                        @closeModal="open = false"
                        :action="'edit'"
                        slot="buttons"
                    />
                </Modal>
                <Modal
                    :title="$t('devices:printQRModal')"
                    :open="openQR"
                    :height="'420'"
                    :width="'400'"
                    v-on:close="closeQRModal()"
                >
                    <ContentQR slot="AEContent" :sn="getDeviceDetails.sn" />
                    <ButtonsQR
                        @closeModal="openQR = false"
                        :action="'edit'"
                        slot="buttons"
                    />
                </Modal>
                <Modal
                    :title="$t('service:additionService')"
                    :open="openModalServ"
                    :height="'715'"
                    v-on:close="closeModalService"
                >
                    <ContentService slot="AEContent" />
                    <ButtonsService
                        :action="'create'"
                        slot="buttons"
                        @closeModal="openModalServ = false"
                    />
                </Modal>
                <Map
                    :open="map.open"
                    @close="map.open = false"
                    :coords="map.coords"
                    :zoom="19"
                />
                <div
                    class="d-flex justify-end flex-row-reverse buttons--details"
                >
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                v-if="edit()"
                                small
                                @click="editDevice()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('devices:editDevice') }}</span>
                    </v-tooltip>
                    <ButtonsInformation
                        @new-service="openModalService"
                        @open-QR-Modal="openQRModal"
                    />
                </div>
            </div>
        </Main>
    </v-container>
</template>
<script>
import store from './../../../store/index'
import Information from './../../../components//Registries/Devices/Details/Information'
import Buttons from './../../../components//Registries/Devices/Modal/Buttons'
import ButtonsInformation from './../../../components//Registries/Devices/Details/Buttons'
import AEContent from './../../../components//Registries/Devices/Modal/Content'

import ContentQR from './../../../components/Registries/Devices/Modal/QRContent'
import ButtonsQR from './../../../components/Registries/Devices/Modal/QRButtons'

import ContentService from './../../../components/Registries/Service/Modal/Content'
import ButtonsService from './../../../components/Registries/Service/Modal/Buttons'

import Imprementation from './../../../components//Registries/Devices/Tabs/Implementation/Details/Implementation'
import Archive from './../../../components/Registries/Devices/Tabs/Archive/Archive'

// import Imprementation from './../../../components//Registries/Devices/Tabs/Implementation/Details/Implementation'
import Another from './../../../components/Registries/Devices/Tabs/Another/Details/Another.vue'

import Map from './../../../components/Global/GoogleMap/Map'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global'] },
    components: {
        Information,
        Buttons,
        AEContent,
        Archive,
        ButtonsInformation,
        ContentQR,
        ButtonsQR,
        Map,
        ContentService,
        ButtonsService,
        Another,
    },
    data() {
        return {
            open: false,
            openQR: false,
            openModalServ: false,
            window: {
                width: 0,
            },
            map: {
                coords: {},
                open: false,
            },
            items: [
                {
                    name: this.$i18n.t('devices:another'),
                    id: 'another',
                    icon: ' mdi-page-next',
                    component: Another,
                },
                {
                    name: 'Dane wdrożeniowe',
                    id: 'implementation',
                    icon: 'mdi-badge-account-horizontal',
                    component: Imprementation,
                },
                {
                    name: 'Archiwum',
                    id: 'archive',
                    icon: ' mdi-archive',
                    component: Archive,
                },
            ],
        }
    },
    computed: {
        ...mapGetters([
            'getDeviceStatuses',
            'getDeviceDetails',
            'getDevicesRegistry',
            'getDeviceStep',
            'getServiceModal',
            'getServiceError',
        ]),
    },
    methods: {
        ...mapMutations([
            'clearDeviceErrors',
            'setDeviceModal',
            'clearServiceModal',
        ]),
        ...mapActions([
            'fetchDevice',
            'updateDeviceStatus',
            'fetchUsers',
            'fetchSettings',
            'fetchRegistriesTable',
        ]),
        async editDevice() {
            this.open = true
            this.clearDeviceErrors()
            this.setDeviceModal()
        },
        edit() {
            return this.checkPermissions('UPDATE')
        },
        changeStatus(status) {
            this.updateDeviceStatus({
                status,
                id: this.getDeviceDetails._id,
            })
        },
        closeModal() {
            this.clearDeviceErrors()
            this.open = false
            this.getDeviceStep.step = 1
        },
        handleResize() {
            this.window.width = window.innerWidth
        },
        openQRModal() {
            this.openQR = true
        },
        openModalService() {
            this.fetchRegistriesTable({
                select: 'icon url',
                where: { url: 'devices' },
            })

            this.clearServiceModal()
            this.openModalServ = true
            this.getServiceModal.device = this.getDeviceDetails
        },
        closeQRModal() {
            this.openQR = false
        },
        closeModalService() {
            this.openModalServ = false
        },
        openMap(location) {
            this.map.coords = location
            this.map.open = true
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
    },

    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchSettings', { type: 'add' })
        store.dispatch('fetchDevice', { id: to.params.id, next })
    },
}
</script>
