<template>
    <v-dialog v-model="isOpen" fullscreen>
        <GmapMap :center="coords" :zoom="zoom" class="map" :options="options">
            <GmapMarker :position="coords" />
        </GmapMap>
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    class="map__close"
                    fab
                    small
                    color="primary"
                    @click="$emit('close')"
                    v-on="on"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('global:closeMap') }}</span>
        </v-tooltip>
    </v-dialog>
</template>
<script>
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        coords: {
            type: Object,
        },
        zoom: {
            type: Number,
            default: 7,
        },
    },
    data() {
        return {
            options: {
                fullscreenControl: false,
            },
        }
    },
    computed: {
        isOpen: {
            get() {
                return this.open
            },
            set(value) {
                this.$emit('close')
            },
        },
    },
}
</script>

<style scoped>
.map {
    width: 100%;
    height: 100%;
}
.map__close {
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>
