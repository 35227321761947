<template>
    <div>
        <v-btn
            @click="QrPrint()"
            :disabled="disabled"
            class="buttons buttons--add"
        >
            {{ $t('devices:print') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data() {
        return {
            disabled: false,
        }
    },
    computed: {
        ...mapGetters(['getSettingsDetails']),
    },
    methods: {
        ...mapActions(['setPrintDevice']),

        async QrPrint() {
            let can = document.getElementsByTagName('canvas')
            let sn = document.getElementById('SN')
            let src = can[0].toDataURL('image/png')
            let mywindow = window.open('', 'PRINT', 'height=300,width=400')

            await mywindow.document.write(`           
                <div style="display: flex; height:100%; flex-direction: column; align-items: center; justify-content:center;">
                    <div style="display: flex; justify-content:center;">
                        <img src="${src}" style="width:100%;" alt="entelli logo">
                    </div>
                    <span style="margin-top: 5px; word-break: break-word; text-align:center"">${
                        sn.innerHTML
                    }</span>
                    <span style="margin-top: 5px; font-size: 10px">${this.$get(
                        this.getSettingsDetails,
                        'website',
                        ''
                    )}</span>
                </div>
            `)
            mywindow.document.close() // necessary for IE >= 10
            mywindow.focus() // necessary for IE >= 10*/
            mywindow.print()

            await this.setPrintDevice({
                id: this.$route.params.id,
                QRprinted: true,
            })
        },
    },
}
</script>
