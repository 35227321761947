var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{attrs:{"cols":"8","xl":"8","lg":"8","md":"6","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"4","lg":"4","md":"6","sm":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('devices:another')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xl":"4","lg":"4","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","value":_vm.getDeviceAnotherDetails.OSP
                                ? _vm.getDeviceAnotherDetails.OSP.contractNo
                                : _vm.getDeviceAnotherDetails.OSP,"label":_vm.$t('devices:OSP')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-file-outline")])]},proxy:true},{key:"append-outer",fn:function(){return [(
                                    _vm.getDeviceAnotherDetails.OSP
                                        ? _vm.getDeviceAnotherDetails.OSP._id
                                        : false
                                )?_c('v-btn',{attrs:{"icon":"","small":"","router":"","to":`/rent-info/${_vm.getDeviceAnotherDetails.OSP._id}`}},[_c('v-icon',{attrs:{"color":"secondary","size":"18"}},[_vm._v("mdi-arrow-right-thick")])],1):_vm._e()]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"4","lg":"4","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","value":`${_vm.$get(
                            _vm.getDeviceAnotherDetails,
                            'contractsNumber.number',
                            ''
                        )} `,"label":_vm.$t('devices:contractsNumber')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-file-outline")])]},proxy:true},{key:"append-outer",fn:function(){return [(
                                    _vm.getDeviceAnotherDetails.contractsNumber
                                )?_c('v-btn',{attrs:{"icon":"","small":"","router":"","to":`/contracts/${_vm.getDeviceAnotherDetails.contractsNumber._id}`}},[_c('v-icon',{attrs:{"color":"secondary","size":"18"}},[_vm._v("mdi-arrow-right-thick")])],1):_vm._e()]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xl":"4","lg":"4","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","value":_vm.getDeviceAnotherDetails.BID,"label":_vm.$t('devices:BID')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-file-outline")])]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xl":"4","lg":"4","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","value":_vm.getDeviceAnotherDetails.asset,"label":_vm.$t('devices:asset')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-fridge")])]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"4","lg":"4","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","value":`${_vm.$get(
                            _vm.getDeviceAnotherDetails,
                            'leasingAgreement.leasingNumber',
                            ''
                        )} `,"label":_vm.$t('devices:leaseAgreement')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-text-subject")])]},proxy:true},{key:"append-outer",fn:function(){return [(
                                    _vm.getDeviceAnotherDetails.leasingAgreement
                                )?_c('v-btn',{attrs:{"icon":"","small":"","router":"","to":`/leasings/${_vm.getDeviceAnotherDetails.leasingAgreement._id}`}},[_c('v-icon',{attrs:{"color":"secondary","size":"18"}},[_vm._v("mdi-arrow-right-thick")])],1):_vm._e()]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('v-textarea',{attrs:{"readonly":"","dense":"","rows":"1","no-resize":"","value":_vm.getDeviceAnotherDetails.comment,"auto-grow":"","label":_vm.$t('devices:comments')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-alert-octagon")])]},proxy:true}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"4","md":"4","lg":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('devices:moreAboutDevice')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center",attrs:{"cols":"12","md":"12","xl":"12","lg":"12","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex px-0 flex-column align-center",attrs:{"cols":"12","lg":"9","xl":"9","md":"9","sm":"9"}},[_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t('devices:servicePackage'),"text":'standardowy',"readonly":true,"disabled":true}}),_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t('devices:packagePrices'),"text":`${_vm.$get(
                                    _vm.getDeviceDetails,
                                    'model.servicePrice.name',
                                    ''
                                )}`,"readonly":true,"disabled":true}}),_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t('devices:surveyCost'),"number":`${_vm.$get(
                                    _vm.getDeviceDetails,
                                    'model.servicePrice.survey.price',
                                    ''
                                )}`,"readonly":true,"disabled":true}}),_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t('devices:repairCost'),"number":`${_vm.$get(
                                    _vm.getDeviceDetails,
                                    'model.servicePrice.repair.price',
                                    ''
                                )}`,"readonly":true,"disabled":true}}),_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t('devices:installationCost'),"number":`${_vm.$get(
                                    _vm.getDeviceDetails,
                                    'model.servicePrice.installation.price',
                                    ''
                                )}`,"readonly":true,"disabled":true}}),_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t('devices:diagnosticsCost'),"number":`${_vm.$get(
                                    _vm.getDeviceDetails,
                                    'model.servicePrice.diagnostics.price',
                                    ''
                                )}`,"readonly":true,"disabled":true}}),_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t('devices:ITservice'),"number":`${_vm.$get(
                                    _vm.getDeviceDetails,
                                    'model.servicePrice.itService.price',
                                    ''
                                )}`,"readonly":true,"disabled":true}}),_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t('devices:remote'),"number":`${_vm.$get(
                                    _vm.getDeviceDetails,
                                    'model.servicePrice.remote.price',
                                    ''
                                )}`,"readonly":true,"disabled":true}}),_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t('devices:firstInstallation'),"text":`${
                                    _vm.$get(
                                        _vm.getDeviceDetails,
                                        'firstInstalationDate',
                                        false
                                    )
                                        ? _vm.$moment(
                                              _vm.getDeviceDetails.firstInstalationDate
                                          ).format('DD-MM-YYYY')
                                        : _vm.$t('global:empty')
                                }`,"readonly":true,"disabled":true}})],1)],1)],1)],1)],1)],1),_c('Buttons')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }