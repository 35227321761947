<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="e1" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getDeviceAnotherError.stepOne
                                            .validation,
                                ]"
                                editable
                                step="1"
                                >{{ $t('devices:another') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getDeviceAnotherError.stepOne
                                            .validation,
                                ]"
                                editable
                                step="2"
                                >{{
                                    $t('devices:moreAboutDevice')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="$t('devices:another')"
                                        />
                                    </v-col>
                                </v-row>
                                <!-- {{ getDeviceAnother }} -->
                                <v-row>
                                    <v-col
                                        cols="12"
                                        xl="4"
                                        lg="4"
                                        md="6"
                                        sm="12"
                                        class="py-0"
                                    >
                                        <v-text-field
                                            dense
                                            v-model="
                                                getContractNo
                                            "
                                            :label="$t('devices:OSP')"
                                            ><template v-slot:prepend>
                                                <v-icon
                                                    color="iconGray"
                                                    size="18"
                                                    >mdi-file-outline</v-icon
                                                >
                                            </template></v-text-field
                                        >
                                    </v-col>
                                </v-row>
                                
                                <v-row>
                                    <v-col
                                        cols="12"
                                        xl="4"
                                        lg="4"
                                        md="6"
                                        sm="12"
                                    >
                                        <!-- <v-text-field
                                            dense
                                            v-model="
                                                getDeviceAnother.leasingNumber
                                            "
                                            :label="$t('devices:leasingNumber')"
                                            ><template v-slot:prepend>
                                                <v-icon
                                                    color="iconGray"
                                                    size="18"
                                                    >mdi-file-outline</v-icon
                                                >
                                            </template></v-text-field
                                        > -->
                                        <v-combobox
                                            dense
                                            v-model="
                                                getDeviceAnother.contractsNumber
                                            "
                                            :hint="`${$t(
                                                'global:startWriting'
                                            )}`"
                                            :label="
                                                $t('devices:contractsNumber')
                                            "
                                            @keyup="
                                                setContracts(
                                                    $event.target.value
                                                )
                                            "
                                            return-object
                                            item-text="number"
                                            item-value="_id"
                                            placeholder="Numer umowy"
                                            :items="getContractsInput.items"
                                            ><template
                                                slot="selection"
                                                slot-scope="data"
                                            >
                                                {{ data.item.number }}
                                            </template>
                                            <template
                                                slot="item"
                                                slot-scope="data"
                                            >
                                                {{ data.item.number }}
                                            </template>
                                            <template v-slot:prepend>
                                                <v-icon
                                                    color="iconGray"
                                                    size="18"
                                                    >mdi-file-outline</v-icon
                                                >
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        xl="4"
                                        lg="4"
                                        md="6"
                                        sm="12"
                                        class="py-0"
                                        ><v-text-field
                                            dense
                                            v-model="getDeviceAnother.BID"
                                            :label="$t('devices:BID')"
                                            ><template v-slot:prepend>
                                                <v-icon
                                                    color="iconGray"
                                                    size="18"
                                                    >mdi-file-outline</v-icon
                                                >
                                            </template></v-text-field
                                        >
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        xl="4"
                                        lg="4"
                                        md="6"
                                        sm="12"
                                        class="py-0"
                                    >
                                        <v-text-field
                                            dense
                                            v-model="getDeviceAnother.asset"
                                            :label="$t('devices:asset')"
                                            ><template v-slot:prepend>
                                                <v-icon
                                                    color="iconGray"
                                                    size="18"
                                                    >mdi-fridge</v-icon
                                                >
                                            </template></v-text-field
                                        ></v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        xl="4"
                                        lg="4"
                                        md="6"
                                        sm="12"
                                    >
                                        <!-- @input="
                                                assignLeasing(
                                                    getDeviceAnother.leasingAgreement
                                                )
                                            " -->
                                        <v-combobox
                                            dense
                                            v-model="
                                                getDeviceAnother.leasingAgreement
                                            "
                                            :hint="`${$t(
                                                'global:startWriting'
                                            )}`"
                                            :label="
                                                $t('devices:leaseAgreement')
                                            "
                                            @keyup="
                                                setLeasing($event.target.value)
                                            "
                                            return-object
                                            item-text="leasingNumber"
                                            item-value="_id"
                                            :items="getLeasingsInput.items"
                                            ><template
                                                slot="selection"
                                                slot-scope="data"
                                            >
                                                {{ data.item.leasingNumber }}
                                            </template>
                                            <template
                                                slot="item"
                                                slot-scope="data"
                                            >
                                                {{ data.item.leasingNumber }}
                                            </template>
                                            <template v-slot:prepend>
                                                <v-icon
                                                    color="iconGray"
                                                    size="18"
                                                    >mdi-text-subject</v-icon
                                                >
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        xl="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                        class="mt-5"
                                    >
                                        <v-textarea
                                            dense
                                            rows="1"
                                            no-resize
                                            v-model="getDeviceAnother.comment"
                                            auto-grow
                                            :label="$t('devices:comments')"
                                            ><template v-slot:prepend>
                                                <v-icon
                                                    color="iconGray"
                                                    size="18"
                                                    >mdi-alert-octagon</v-icon
                                                >
                                            </template></v-textarea
                                        >
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-col cols="12" md="12" lg="12">
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                        >
                                            <Separator
                                                :text="
                                                    $t(
                                                        'devices:moreAboutDevice'
                                                    )
                                                "
                                            /> </v-col
                                    ></v-row>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            md="12"
                                            xl="12"
                                            lg="12"
                                            sm="12"
                                            class="d-flex flex-row align-center"
                                        >
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    lg="9"
                                                    xl="9"
                                                    md="9"
                                                    sm="9"
                                                    class="d-flex px-0 flex-column align-center"
                                                >
                                                    <SummaryLine
                                                        :firtstLine="
                                                            $t(
                                                                'devices:servicePackage'
                                                            )
                                                        "
                                                        :text="'standardowy'"
                                                        class="summaryLine--font"
                                                        :readonly="true"
                                                        :disabled="true"
                                                    />
                                                    <!-- <SummaryLine
                                                        class="summaryLine--font"
                                                        :firtstLine="
                                                            $t(
                                                                'devices:dedicatedPrice'
                                                            )
                                                        "
                                                        :isCheckbox="true"
                                                        :checkboxValue="
                                                            checkboxValue
                                                        "
                                                        :readonly="false"
                                                        :disabled="false"
                                                    >
                                                        <template
                                                            v-slot:checkbox
                                                        >
                                                            <v-checkbox
                                                                v-model="
                                                                    getDeviceAnother.dedicatedPrice
                                                                "
                                                                :readonly="
                                                                    false
                                                                "
                                                                color="secondary"
                                                                class="summaryLine__checkbox"
                                                            ></v-checkbox> </template
                                                    ></SummaryLine> -->
                                                    <SummaryLine
                                                        class="summaryLine--font"
                                                        :firtstLine="
                                                            $t(
                                                                'devices:packagePrices'
                                                            )
                                                        "
                                                        :text="`${$get(
                                                            getDeviceDetails,
                                                            'model.servicePrice.name',
                                                            ''
                                                        )}`"
                                                        :readonly="true"
                                                        :disabled="true"
                                                    />
                                                    <SummaryLine
                                                        class="summaryLine--font"
                                                        :firtstLine="
                                                            $t(
                                                                'devices:surveyCost'
                                                            )
                                                        "
                                                        :number="`${$get(
                                                            getDeviceDetails,
                                                            'model.servicePrice.survey.price',
                                                            ''
                                                        )}`"
                                                        :readonly="true"
                                                        :disabled="true"
                                                    />
                                                    <SummaryLine
                                                        class="summaryLine--font"
                                                        :firtstLine="
                                                            $t(
                                                                'devices:repairCost'
                                                            )
                                                        "
                                                        :number="`${$get(
                                                            getDeviceDetails,
                                                            'model.servicePrice.repair.price',
                                                            ''
                                                        )}`"
                                                        :readonly="true"
                                                        :disabled="true"
                                                    />
                                                    <SummaryLine
                                                        class="summaryLine--font"
                                                        :firtstLine="
                                                            $t(
                                                                'devices:installationCost'
                                                            )
                                                        "
                                                        :number="`${$get(
                                                            getDeviceDetails,
                                                            'model.servicePrice.installation.price',
                                                            ''
                                                        )}`"
                                                        :readonly="true"
                                                        :disabled="true"
                                                    />
                                                    <SummaryLine
                                                        class="summaryLine--font"
                                                        :firtstLine="
                                                            $t(
                                                                'devices:diagnosticsCost'
                                                            )
                                                        "
                                                        :number="`${$get(
                                                            getDeviceDetails,
                                                            'model.servicePrice.diagnostics.price',
                                                            ''
                                                        )}`"
                                                        :readonly="true"
                                                        :disabled="true"
                                                    />
                                                    <SummaryLine
                                                        class="summaryLine--font"
                                                        :firtstLine="
                                                            $t(
                                                                'devices:ITservice'
                                                            )
                                                        "
                                                        :number="`${$get(
                                                            getDeviceDetails,
                                                            'model.servicePrice.itService.price',
                                                            ''
                                                        )}`"
                                                        :readonly="true"
                                                        :disabled="true"
                                                    />
                                                    <SummaryLine
                                                        class="summaryLine--font"
                                                        :firtstLine="
                                                            $t('devices:remote')
                                                        "
                                                        :number="`${$get(
                                                            getDeviceDetails,
                                                            'model.servicePrice.remote.price',
                                                            ''
                                                        )}`"
                                                        :readonly="true"
                                                        :disabled="true"
                                                    />
                                                    <SummaryLine
                                                        class="summaryLine--font"
                                                        :firtstLine="
                                                            $t(
                                                                'devices:firstInstallation'
                                                            )
                                                        "
                                                        :text="`${
                                                            $get(
                                                                getDeviceDetails,
                                                                'firstInstalationDate',
                                                                false
                                                            )
                                                                ? $moment(
                                                                      getDeviceDetails.firstInstalationDate
                                                                  ).format(
                                                                      'DD-MM-YYYY'
                                                                  )
                                                                : $t(
                                                                      'global:empty'
                                                                  )
                                                        }`"
                                                        :readonly="true"
                                                        :disabled="true"
                                                    />
                                                </v-col>
                                                <!-- <v-col
                                                    cols="12"
                                                    lg="3"
                                                    v-if="
                                                        getDeviceAnother.dedicatedPrice
                                                    "
                                                    md="3"
                                                    sm="3"
                                                    class="d-flex flex-column dedicated--prices pr-0"
                                                >
                                                    <span>
                                                        {{
                                                            $t(
                                                                'devices:dedicated'
                                                            )
                                                        }}
                                                    </span>
                                                    <money-field
                                                        class="margin--input"
                                                        :disabled="false"
                                                        :readonly="false"
                                                        v-model="
                                                            getDeviceAnother.survey
                                                        "
                                                    ></money-field>
                                                    <money-field
                                                        :disabled="false"
                                                        :readonly="false"
                                                        class="margin--input"
                                                        v-model="
                                                            getDeviceAnother.repair
                                                        "
                                                    ></money-field>
                                                    <money-field
                                                        :disabled="false"
                                                        :readonly="false"
                                                        class="margin--input"
                                                        v-model="
                                                            getDeviceAnother.installation
                                                        "
                                                    ></money-field>
                                                    <money-field
                                                        :disabled="false"
                                                        :readonly="false"
                                                        class="margin--input"
                                                        v-model="
                                                            getDeviceAnother.diagnostics
                                                        "
                                                    ></money-field>
                                                    <money-field
                                                        :disabled="false"
                                                        :readonly="false"
                                                        class="margin--input"
                                                        v-model="
                                                            getDeviceAnother.itService
                                                        "
                                                    ></money-field>
                                                </v-col> -->
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    data() {
        return {
            editable: true,
            checkboxValue: false,
            e1: 1,
        }
    },
    methods: {
        ...mapMutations(['setLeasingsInput', 'setContractsInput']),
        ...mapActions(['fetchLeasingsInput', 'fetchContractsInput']),
        setLeasing(search) {
            if (search.length >= 3) {
                this.setLeasingsInput({ search })
                this.fetchLeasingsInput()
            } else {
                this.getLeasingsInput.items = []
            }
        },
        setContracts(search) {
            if (search.length >= 3) {
                this.setContractsInput({ search })
                this.fetchContractsInput()
            } else {
                this.getContractsInput.items = []
            }
        },
    },
    computed: {
        ...mapGetters([
            'getDeviceDetails',
            'getDeviceAnother',
            'getDeviceAnotherError',
            'getLeasingsInput',
            'getContractsInput',
        ]),
        getContractNo: {
            get() {
                return this.getDeviceAnother.OSP?.contractNo
            },
        },
    },
}
</script>
<style lang="sass" scoped>
.margin--input
    margin-top: 7px !important

.padding-r
    padding-right: 25px !important
.dedicated--prices
    font-size: 14px !important
    justify-content: space-between
    height: 200px,
    padding: 0 !important
    margin-left: -25px
    margin-top: 87px
    color: #9e9e9e !important
.client--section
    margin-top: 28px
.summaryLine--font
    font-size: 14px !important
</style>
