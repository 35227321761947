<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="6" xl="6" lg="6" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" md="12">
                        <Separator :text="$t('devices:basicInformations')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12" lg="12">
                        <v-textarea
                            auto-grow
                            rows="1"
                            readonly
                            :label="$t('devices:data')"
                            :value="getImplementationDetails.data"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12" lg="12">
                        <v-textarea
                            rows="1"
                            auto-grow
                            readonly
                            :label="$t('devices:settings')"
                            :value="getImplementationDetails.settings"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <Buttons />
    </v-container>
</template>

<script>
import Buttons from './../Buttons/Buttons'
import { mapActions, mapGetters } from 'vuex'
export default {
    data() {
        return {}
    },

    computed: {
        ...mapGetters(['getImplementationDetails']),
    },
    methods: {
        ...mapActions(['fetchImplementation']),
    },
    components: {
        Buttons,
    },
    beforeMount() {
        this.fetchImplementation({ id: this.$route.params.id })
    },
}
</script>
