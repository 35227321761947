<template>
    <div>
        <TableDetails
            @sort="setSort"
            @search="search"
            :headers="headers"
            :page="getArchivesTable.page"
            :items="getArchivesTable.items"
            :length="getArchivesTable.pages"
            @change-page="setPage"
        >
            <Columns slot="columns" :items="getArchivesTable.items" />
        </TableDetails>

        <div class="d-flex summary--cost flex-column">
            <span>
                <span style="display: inline-block;">
                    {{
                        `Koszty ostatniego klienta Usługi: ${$get(
                            getServiceTime(),
                            'h',
                            '0'
                        )}  h - `
                    }}
                    <div v-if="total">
                        <div
                            v-if="
                                getServiceTime().total ===
                                $t('service-prices:noOwnManHoursCostSet')
                            "
                            class="no-ownManHoursCost"
                            style="display: inline-block;"
                        >
                            {{ ` ${$get(getServiceTime(), 'total', '0')}  ` }}
                        </div>
                        <div v-else style="display: inline-block;">
                            {{ getServiceTime().total }}
                        </div>
                    </div>
                </span>
                <span style="display-inline">
                    zł; Części:
                </span>

                <span
                    >{{ setPartsSum().toFixed(2).replace('.', ',') }} zł +
                    VAT</span
                >
            </span>
            <span>
                <span style="display-inline">
                    {{
                        `Koszty całkowite: Usługi: ${$get(
                            setTimeSum(),
                            'hours',
                            ''
                        )} ${$t('devices:h')}  - `
                    }}
                    <div
                        v-if="
                            setTimeSum().total ===
                            $t('service-prices:noOwnManHoursCostSet')
                        "
                        style="display: inline-block;"
                    >
                        <span class="no-ownManHoursCost">
                            {{ ` ${$get(setTimeSum(), 'total', '0')}  ` }}
                        </span>
                    </div>
                    <div style="display: inline-block;" v-else>
                        <span style="display-inline">
                            {{ ` ${$get(setTimeSum(), 'total', '0')} ` }}
                        </span>
                    </div>
                </span>
                <span> zł; Części: </span>

                <span>{{ setPartsSum().toFixed(2).replace('.', ',') }}</span>
                <span class="text"> zł + VAT</span>
            </span>
        </div>
    </div>
</template>
<script></script>
<script>
import Columns from './Details/Columns'
import moment from 'moment'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['devices'] },
    data() {
        return {
            ownManHoursCost: '',
            total: 0,
            open: false,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' PLN',
                precision: 2,
                masked: false,
            },
            length: 10,
            headers: [
                {
                    text: this.$t('devices:index'),
                    value: 'index',
                    width: '10%',
                },
                {
                    text: this.$t('devices:created'),
                    value: 'created',
                    width: '10%',
                },
                {
                    text: this.$t('devices:technic'),
                    value: 'technic',
                    width: '10%',
                },
                {
                    text: this.$t('devices:service'),
                    value: 'service',
                    width: '25%',
                },
                {
                    text: this.$t('devices:deduce'),
                    value: 'deduce',
                    width: '24%',
                },
                {
                    text: this.$t('devices:cost'),
                    value: 'cost',
                    width: '15%',
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '6%',
                    sortable: false,
                },
            ],
            items: [],
        }
    },

    computed: {
        ...mapGetters([
            'getArchivesTable',
            'getDeviceDetails',
            'getAllArchives',
        ]),
    },
    methods: {
        ...mapMutations(['setArchivesTable', 'clearArchivesTable']),
        ...mapActions(['fetchArchives', 'fetchAllArchives']),
        async fetch() {
            await this.fetchAllArchives({ id: this.getDeviceDetails._id })
            this.ownManHoursCost = this.getDeviceDetails?.model?.servicePrice?.ownManHoursCost?.price
        },
        getServiceTime() {
            if (this.getAllArchives.items[0]) {
                const {
                    startTime,
                    startDate,
                    endTime,
                    endDate,
                } = this.getAllArchives.items[0].realization

                if (!endDate || !startDate || !endTime || !startTime)
                    return { h: '0', m: '0' }

                let end = moment(
                    `${moment(endDate).format('YYYY-MM-DD')}T${endTime}:00`
                )
                let start = moment(
                    `${moment(startDate).format('YYYY-MM-DD')}T${startTime}:00`
                )
                let result = moment.duration(end.diff(start))

                let addHour = 0
                if (result._data.minutes >= 1) {
                    addHour = 1
                }

                const h = result._data.hours + result._data.days * 24 + addHour
                let total = this.$t('service-prices:noOwnManHoursCostSet')

                if (this.ownManHoursCost) {
                    total = (h * this.ownManHoursCost).toFixed(2)
                }

                return {
                    h: h,
                    m: result._data.minutes,
                    d: result._data.days,
                    total: total,
                }
            }
        },
        isSameClient() {
            const items = this.getAllArchives.items
            const clients = items.map(item => item.client)
            const key = this.getDeviceDetails.client._id
            const filter = clients.map(item => (item === key ? true : false))
            return filter
        },
        setPartsSum(filter) {
            let items = this.getAllArchives.items
            if (filter) {
                let temp = []
                filter = filter
                    .map((item, index) => (item ? index : null))
                    .filter(e => e != null)
                filter.forEach(i => {
                    temp.push(items.slice(i, i + 1))
                })
                items = temp.flat()
            }
            items = items.map(item => (item?.parts?.sum ? item.parts.sum : 0))
            const sum = items.reduce((a, b) => a + b, 0)

            return sum
        },
        setTimeSum(filter) {
            const sumArray = array => {
                return array.filter(e => e).reduce((a, b) => a + b, 0)
            }
            const finalValue = (days, hours, minutes) => {
                if (minutes / 60 >= 1) {
                    minutes = minutes % 60
                    hours = hours + Math.ceil(minutes / 60)
                }

                let total = this.$t('service-prices:noOwnManHoursCostSet')
                if (this.ownManHoursCost) {
                    total = (hours * this.ownManHoursCost).toFixed(2)
                }

                return {
                    hours: days * 24 + hours,
                    minutes: minutes,
                    total,
                }
            }
            let temp = this.getAllArchives.items

            if (filter) {
                let _ = []
                filter = filter
                    .map((item, index) => (item ? index : null))
                    .filter(e => e != null)
                filter.forEach(i => {
                    _.push(temp.slice(i, i + 1))
                })
                temp = _.flat()
            }
            if (temp.length === 0) return { hours: 0, minutes: 0 }
            let days = []
            let hours = []
            let minutes = []
            temp = temp.map(item => [
                item.realization.startTime,
                item.realization.endTime,
                item.realization.startDate,
                item.realization.endDate,
            ])
            temp.forEach(item => {
                const [startTime, endTime, startDate, endDate] = item

                if (!endDate || !startDate || !endTime || !startTime)
                    return { h: '--', m: '--', d: '--' }

                let end = moment(
                    `${moment(endDate).format('YYYY-MM-DD')}T${endTime}:00`
                )
                let start = moment(
                    `${moment(startDate).format('YYYY-MM-DD')}T${startTime}:00`
                )
                let result = moment.duration(end.diff(start))

                days.push()
                hours.push(result._data.hours)
                minutes.push(result._data.minutes)
                days.push(result._data.days)
            })
            const daySum = sumArray(days)
            const hoursSum = sumArray(hours)
            const minutesSum = sumArray(minutes)

            let addHour = 0
            if (minutesSum >= 1) {
                addHour = 1
            }
            return finalValue(daySum, hoursSum + addHour, minutesSum)
        },
        async clear() {
            await this.clearArchivesTable()
        },
        search(value) {
            this.setArchivesTable({ search: value })
            this.fetchArchives({ id: this.getDeviceDetails._id })
        },
        setSort(sort) {
            this.setArchivesTable({ sort })
            this.fetchArchives({ id: this.getDeviceDetails._id })
        },
        setPage(page) {
            this.setArchivesTable({ page })
            this.fetchArchives({ id: this.getDeviceDetails._id })
        },
    },

    components: {
        Columns,
    },

    beforeMount() {
        this.clear()
        this.fetch()
    },
}
</script>
<style lang="sass" scoped>
.money--archive
    font-size: 16px !important
    input
        max-width: max-content !important
.summary--cost
    position: absolute !important
    right: 35%
    bottom: 45px
    font-size: clamp(11px, 13px, 15px)
.no-ownManHoursCost
    color: red
    font-weight: bold
.v-money
    max-width: max-content !important
</style>
