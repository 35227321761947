<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="8" xl="8" lg="8" md="6" sm="12">
                <v-row>
                    <v-col cols="12" xl="4" lg="4" md="6" sm="12">
                        <Separator :text="$t('devices:another')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" xl="4" lg="4" md="6" sm="12" class="py-0">
                        <v-text-field
                            readonly
                            dense
                            :value="
                                getDeviceAnotherDetails.OSP
                                    ? getDeviceAnotherDetails.OSP.contractNo
                                    : getDeviceAnotherDetails.OSP
                            "
                            :label="$t('devices:OSP')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-file-outline</v-icon
                                >
                            </template>
                            <template v-slot:append-outer>
                                <v-btn
                                    v-if="
                                        getDeviceAnotherDetails.OSP
                                            ? getDeviceAnotherDetails.OSP._id
                                            : false
                                    "
                                    icon
                                    small
                                    router
                                    :to="`/rent-info/${getDeviceAnotherDetails.OSP._id}`"
                                    ><v-icon color="secondary" size="18"
                                        >mdi-arrow-right-thick</v-icon
                                    ></v-btn
                                >
                            </template></v-text-field
                        >
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" xl="4" lg="4" md="6" sm="12">
                        <v-text-field
                            readonly
                            dense
                            :value="`${$get(
                                getDeviceAnotherDetails,
                                'contractsNumber.number',
                                ''
                            )} `"
                            :label="$t('devices:contractsNumber')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-file-outline</v-icon
                                >
                            </template>
                            <template v-slot:append-outer>
                                <v-btn
                                    v-if="
                                        getDeviceAnotherDetails.contractsNumber
                                    "
                                    icon
                                    small
                                    router
                                    :to="`/contracts/${getDeviceAnotherDetails.contractsNumber._id}`"
                                    ><v-icon color="secondary" size="18"
                                        >mdi-arrow-right-thick</v-icon
                                    ></v-btn
                                >
                            </template></v-text-field
                        >
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" xl="4" lg="4" md="6" sm="12" class="py-0"
                        ><v-text-field
                            readonly
                            dense
                            :value="getDeviceAnotherDetails.BID"
                            :label="$t('devices:BID')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-file-outline</v-icon
                                >
                            </template></v-text-field
                        >
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" xl="4" lg="4" md="6" sm="12" class="py-0">
                        <v-text-field
                            readonly
                            dense
                            :value="getDeviceAnotherDetails.asset"
                            :label="$t('devices:asset')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-fridge</v-icon
                                >
                            </template></v-text-field
                        ></v-col
                    >
                </v-row>
                <v-row>
                    <v-col cols="12" xl="4" lg="4" md="6" sm="12">
                        <v-text-field
                            readonly
                            dense
                            :value="`${$get(
                                getDeviceAnotherDetails,
                                'leasingAgreement.leasingNumber',
                                ''
                            )} `"
                            :label="$t('devices:leaseAgreement')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-text-subject</v-icon
                                >
                            </template>
                            <template v-slot:append-outer>
                                <v-btn
                                    icon
                                    small
                                    v-if="
                                        getDeviceAnotherDetails.leasingAgreement
                                    "
                                    router
                                    :to="`/leasings/${getDeviceAnotherDetails.leasingAgreement._id}`"
                                    ><v-icon color="secondary" size="18"
                                        >mdi-arrow-right-thick</v-icon
                                    ></v-btn
                                >
                            </template>
                        </v-text-field>
                    </v-col>
                    <!-- </v-col> -->
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        xl="12"
                        lg="12"
                        md="12"
                        sm="12"
                        class="mt-5"
                    >
                        <v-textarea
                            readonly
                            dense
                            rows="1"
                            no-resize
                            :value="getDeviceAnotherDetails.comment"
                            auto-grow
                            :label="$t('devices:comments')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-alert-octagon</v-icon
                                >
                            </template></v-textarea
                        >
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4" md="4" lg="4">
                <v-row>
                    <v-col cols="12" lg="12" md="12" sm="12">
                        <Separator
                            :text="$t('devices:moreAboutDevice')"
                        /> </v-col
                ></v-row>
                <v-row>
                    <v-col
                        cols="12"
                        md="12"
                        xl="12"
                        lg="12"
                        sm="12"
                        class="d-flex flex-row align-center"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                lg="9"
                                xl="9"
                                md="9"
                                sm="9"
                                class="d-flex px-0 flex-column align-center"
                            >
                                <SummaryLine
                                    :firtstLine="$t('devices:servicePackage')"
                                    :text="'standardowy'"
                                    class="summaryLine--font"
                                    :readonly="true"
                                    :disabled="true"
                                />

                                <SummaryLine
                                    class="summaryLine--font"
                                    :firtstLine="$t('devices:packagePrices')"
                                    :text="`${$get(
                                        getDeviceDetails,
                                        'model.servicePrice.name',
                                        ''
                                    )}`"
                                    :readonly="true"
                                    :disabled="true"
                                />
                                <SummaryLine
                                    class="summaryLine--font"
                                    :firtstLine="$t('devices:surveyCost')"
                                    :number="`${$get(
                                        getDeviceDetails,
                                        'model.servicePrice.survey.price',
                                        ''
                                    )}`"
                                    :readonly="true"
                                    :disabled="true"
                                />
                                <SummaryLine
                                    class="summaryLine--font"
                                    :firtstLine="$t('devices:repairCost')"
                                    :number="`${$get(
                                        getDeviceDetails,
                                        'model.servicePrice.repair.price',
                                        ''
                                    )}`"
                                    :readonly="true"
                                    :disabled="true"
                                />
                                <SummaryLine
                                    class="summaryLine--font"
                                    :firtstLine="$t('devices:installationCost')"
                                    :number="`${$get(
                                        getDeviceDetails,
                                        'model.servicePrice.installation.price',
                                        ''
                                    )}`"
                                    :readonly="true"
                                    :disabled="true"
                                />
                                <SummaryLine
                                    class="summaryLine--font"
                                    :firtstLine="$t('devices:diagnosticsCost')"
                                    :number="`${$get(
                                        getDeviceDetails,
                                        'model.servicePrice.diagnostics.price',
                                        ''
                                    )}`"
                                    :readonly="true"
                                    :disabled="true"
                                />
                                <SummaryLine
                                    class="summaryLine--font"
                                    :firtstLine="$t('devices:ITservice')"
                                    :number="`${$get(
                                        getDeviceDetails,
                                        'model.servicePrice.itService.price',
                                        ''
                                    )}`"
                                    :readonly="true"
                                    :disabled="true"
                                />
                                <SummaryLine
                                    class="summaryLine--font"
                                    :firtstLine="$t('devices:remote')"
                                    :number="`${$get(
                                        getDeviceDetails,
                                        'model.servicePrice.remote.price',
                                        ''
                                    )}`"
                                    :readonly="true"
                                    :disabled="true"
                                />
                                <SummaryLine
                                    class="summaryLine--font"
                                    :firtstLine="
                                        $t('devices:firstInstallation')
                                    "
                                    :text="`${
                                        $get(
                                            getDeviceDetails,
                                            'firstInstalationDate',
                                            false
                                        )
                                            ? $moment(
                                                  getDeviceDetails.firstInstalationDate
                                              ).format('DD-MM-YYYY')
                                            : $t('global:empty')
                                    }`"
                                    :readonly="true"
                                    :disabled="true"
                                />
                            </v-col>
                            <!-- <v-col
                                cols="12"
                                lg="3"
                                v-if="getDeviceAnotherDetails.dedicatedPrice"
                                md="3"
                                sm="3"
                                class="d-flex flex-column dedicated--prices pr-0"
                            >
                                <span>
                                    {{ $t('devices:dedicated') }}
                                </span>
                                <money
                                    class="money-value"
                                    :value="`${$get(
                                        getDeviceAnotherDetails,
                                        'survey.dedicated',
                                        ''
                                    )} `"
                                    :readonly="true"
                                    :disabled="true"
                                    v-bind="money"
                                ></money>
                                <money
                                    class="money-value"
                                    :value="`${$get(
                                        getDeviceAnotherDetails,
                                        'repair.dedicated',
                                        ''
                                    )} `"
                                    :readonly="true"
                                    :disabled="true"
                                    v-bind="money"
                                ></money>
                                <money
                                    class="money-value"
                                    :value="`${$get(
                                        getDeviceAnotherDetails,
                                        'installation.dedicated',
                                        ''
                                    )} `"
                                    :readonly="true"
                                    :disabled="true"
                                    v-bind="money"
                                ></money>
                                <money
                                    class="money-value"
                                    :value="`${$get(
                                        getDeviceAnotherDetails,
                                        'diagnostics.dedicated',
                                        ''
                                    )} `"
                                    :readonly="true"
                                    :disabled="true"
                                    v-bind="money"
                                ></money>
                                <money
                                    class="money-value"
                                    :value="`${$get(
                                        getDeviceAnotherDetails,
                                        'itService.dedicated',
                                        ''
                                    )} `"
                                    :readonly="true"
                                    :disabled="true"
                                    v-bind="money"
                                ></money>
                            </v-col> -->
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <Buttons />
    </v-container>
</template>

<script>
import Buttons from './../Buttons/Buttons.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
    data() {
        return {
            checkboxValue: true,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' PLN',
                precision: 2,
                masked: false,
            },
        }
    },

    computed: {
        ...mapGetters(['getDeviceDetails', 'getDeviceAnotherDetails']),
    },
    methods: {
        ...mapActions(['fetchAnother']),
        // goTo() {
        //     if (
        //         this.getDeviceDetails.leasing &&
        //         this.getDeviceDetails.leasing._id &&
        //         this.checkPermissions()
        //     ) {
        //         this.$router.push(
        //             '/leasings/' + this.getDeviceDetails.leasing._id
        //         )
        //     }
        // },
    },
    components: {
        Buttons,
    },
    beforeMount() {
        this.fetchAnother({ id: this.$route.params.id })
    },
}
</script>
<style lang="sass" scoped>
.margin--avatar
    margin-top: -5px
    margin-left: 5px
.weight--700
    font-weight: 700
.input--sn > .v-input__control
    background-color: yellow !important
.comment-margin
    margin-top: 60px
.margin--input
    margin-top: 9px !important
.dedicated--prices
    justify-content: space-between
    font-size: 14px !important
    height: 224px
    margin-left: -25px
    margin-top: 78px
    color: #9e9e9e !important
.dedicated--prices span
    margin-bottom: 5px
.summaryLine--font
    font-size: 14px !important
.money-value
    padding: 5px 0px
    font-size: 14px !important
    color: #9e9e9e !important
</style>
<style lang="sass">
.input--sn > .v-input__control > .v-input__slot > .v-text-field__slot > input
    background-color: #ffff86 !important
    font-size: 17px !important
    padding-left: 7px !important
.v-textarea textarea
    min-height: 22px !important
</style>
