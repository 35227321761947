var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () =>
                                    _vm.getDeviceAnotherError.stepOne
                                        .validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('devices:another')))]),_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () =>
                                    _vm.getDeviceAnotherError.stepOne
                                        .validation,
                            ],"editable":"","step":"2"}},[_vm._v(_vm._s(_vm.$t('devices:moreAboutDevice')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('devices:another')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xl":"4","lg":"4","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('devices:OSP')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-file-outline")])]},proxy:true}]),model:{value:(
                                            _vm.getContractNo
                                        ),callback:function ($$v) {
                                            _vm.getContractNo
                                        =$$v},expression:"\n                                            getContractNo\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"4","lg":"4","md":"6","sm":"12"}},[_c('v-combobox',{attrs:{"dense":"","hint":`${_vm.$t(
                                            'global:startWriting'
                                        )}`,"label":_vm.$t('devices:contractsNumber'),"return-object":"","item-text":"number","item-value":"_id","placeholder":"Numer umowy","items":_vm.getContractsInput.items},on:{"keyup":function($event){return _vm.setContracts(
                                                $event.target.value
                                            )}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.number)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.number)+" ")]}},{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-file-outline")])]},proxy:true}]),model:{value:(
                                            _vm.getDeviceAnother.contractsNumber
                                        ),callback:function ($$v) {_vm.$set(_vm.getDeviceAnother, "contractsNumber", $$v)},expression:"\n                                            getDeviceAnother.contractsNumber\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xl":"4","lg":"4","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('devices:BID')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-file-outline")])]},proxy:true}]),model:{value:(_vm.getDeviceAnother.BID),callback:function ($$v) {_vm.$set(_vm.getDeviceAnother, "BID", $$v)},expression:"getDeviceAnother.BID"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xl":"4","lg":"4","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('devices:asset')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-fridge")])]},proxy:true}]),model:{value:(_vm.getDeviceAnother.asset),callback:function ($$v) {_vm.$set(_vm.getDeviceAnother, "asset", $$v)},expression:"getDeviceAnother.asset"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"4","lg":"4","md":"6","sm":"12"}},[_c('v-combobox',{attrs:{"dense":"","hint":`${_vm.$t(
                                            'global:startWriting'
                                        )}`,"label":_vm.$t('devices:leaseAgreement'),"return-object":"","item-text":"leasingNumber","item-value":"_id","items":_vm.getLeasingsInput.items},on:{"keyup":function($event){return _vm.setLeasing($event.target.value)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.leasingNumber)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.leasingNumber)+" ")]}},{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-text-subject")])]},proxy:true}]),model:{value:(
                                            _vm.getDeviceAnother.leasingAgreement
                                        ),callback:function ($$v) {_vm.$set(_vm.getDeviceAnother, "leasingAgreement", $$v)},expression:"\n                                            getDeviceAnother.leasingAgreement\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('v-textarea',{attrs:{"dense":"","rows":"1","no-resize":"","auto-grow":"","label":_vm.$t('devices:comments')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-alert-octagon")])]},proxy:true}]),model:{value:(_vm.getDeviceAnother.comment),callback:function ($$v) {_vm.$set(_vm.getDeviceAnother, "comment", $$v)},expression:"getDeviceAnother.comment"}})],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('Separator',{attrs:{"text":_vm.$t(
                                                    'devices:moreAboutDevice'
                                                )}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center",attrs:{"cols":"12","md":"12","xl":"12","lg":"12","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex px-0 flex-column align-center",attrs:{"cols":"12","lg":"9","xl":"9","md":"9","sm":"9"}},[_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t(
                                                            'devices:servicePackage'
                                                        ),"text":'standardowy',"readonly":true,"disabled":true}}),_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t(
                                                            'devices:packagePrices'
                                                        ),"text":`${_vm.$get(
                                                        _vm.getDeviceDetails,
                                                        'model.servicePrice.name',
                                                        ''
                                                    )}`,"readonly":true,"disabled":true}}),_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t(
                                                            'devices:surveyCost'
                                                        ),"number":`${_vm.$get(
                                                        _vm.getDeviceDetails,
                                                        'model.servicePrice.survey.price',
                                                        ''
                                                    )}`,"readonly":true,"disabled":true}}),_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t(
                                                            'devices:repairCost'
                                                        ),"number":`${_vm.$get(
                                                        _vm.getDeviceDetails,
                                                        'model.servicePrice.repair.price',
                                                        ''
                                                    )}`,"readonly":true,"disabled":true}}),_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t(
                                                            'devices:installationCost'
                                                        ),"number":`${_vm.$get(
                                                        _vm.getDeviceDetails,
                                                        'model.servicePrice.installation.price',
                                                        ''
                                                    )}`,"readonly":true,"disabled":true}}),_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t(
                                                            'devices:diagnosticsCost'
                                                        ),"number":`${_vm.$get(
                                                        _vm.getDeviceDetails,
                                                        'model.servicePrice.diagnostics.price',
                                                        ''
                                                    )}`,"readonly":true,"disabled":true}}),_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t(
                                                            'devices:ITservice'
                                                        ),"number":`${_vm.$get(
                                                        _vm.getDeviceDetails,
                                                        'model.servicePrice.itService.price',
                                                        ''
                                                    )}`,"readonly":true,"disabled":true}}),_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t('devices:remote'),"number":`${_vm.$get(
                                                        _vm.getDeviceDetails,
                                                        'model.servicePrice.remote.price',
                                                        ''
                                                    )}`,"readonly":true,"disabled":true}}),_c('SummaryLine',{staticClass:"summaryLine--font",attrs:{"firtstLine":_vm.$t(
                                                            'devices:firstInstallation'
                                                        ),"text":`${
                                                        _vm.$get(
                                                            _vm.getDeviceDetails,
                                                            'firstInstalationDate',
                                                            false
                                                        )
                                                            ? _vm.$moment(
                                                                  _vm.getDeviceDetails.firstInstalationDate
                                                              ).format(
                                                                  'DD-MM-YYYY'
                                                              )
                                                            : _vm.$t(
                                                                  'global:empty'
                                                              )
                                                    }`,"readonly":true,"disabled":true}})],1)],1)],1)],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }