<template>
    <div>
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    :disabled="disabled"
                    fab
                    v-on="on"
                    small
                    @click="$emit('new-service')"
                    class="buttons--add"
                >
                    <v-icon small>mdi-wrench</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('devices:newService') }}</span>
        </v-tooltip>
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    dark
                    v-on="on"
                    small
                    @click="$emit('open-QR-Modal')"
                    :class="`buttons${getDeviceDetails.QRprinted?'--cancel':'--add'} mx-2`"
                    
                >
                    <v-icon small>mdi-qrcode-scan</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('devices:printQRCode') }}</span>
        </v-tooltip>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            disabled: false,
        }
    },
    computed: { ...mapGetters(['getDeviceDetails']) },
    methods: {},
    beforeMount() {
        if (this.getDeviceDetails.status.name == 'Aktualny') {
            this.disabled = false
        } else {
            this.disabled = true
        }
    },
}
</script>
