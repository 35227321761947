<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="2" xl="2" lg="5" md="12" sm="12" class="pl-5">
                <v-row>
                    <v-col cols="12" class="pb-0"
                        ><div class="weight--700 headline">
                            {{
                                $get(getDeviceDetails, 'model.manufacturer', '')
                            }}
                        </div></v-col
                    >  
                </v-row>
                <v-row>
                  
                    <v-col cols="12" class="py-0 pt-5">
                        <span class="weight--700">S/N:</span>
                        <span> {{ getDeviceDetails.sn }}</span>
                    </v-col>
                      <v-col  cols="12" class="py-0 pt-1">
                        <span class="weight--700"> ID:</span>
                        <span> {{ getDeviceDetails.identificationNumber}}</span>
                    </v-col>
                </v-row>
                <!-- <v-row>
                    <v-col cols="12"
                        ><span class="weight--700"
                            >{{ $t('devices:options') }}:</span
                        >
                        {{
                            getDeviceDetails.options
                                ? getDeviceDetails.options
                                : ''
                        }}
                    </v-col>
                </v-row> -->
            </v-col>
            <v-col cols="7" xl="7" lg="7" md="12" sm="12">
                <v-row>
                    <v-col cols="12" md="12" class="pt-0">
                        <Separator :text="$t('devices:device')">
                            <div slot="other">
                                <v-tooltip
                                    bottom
                                    v-if="
                                        getDeviceDetails.monitoringDevice ===
                                        true
                                    "
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-avatar
                                            class="cursor-pointer margin--avatar"
                                            v-on="on"
                                            color="#1167b1"
                                            size="25"
                                        >
                                            <span class="white--text"
                                                >M</span
                                            ></v-avatar
                                        >
                                    </template>
                                    <span>{{
                                        $t('devices:monitoringDevice')
                                    }}</span>
                                </v-tooltip>
                                <v-tooltip
                                    bottom
                                    v-if="
                                        getDeviceDetails.guaranteeTo &&
                                        $moment(
                                            getDeviceDetails.guaranteeTo
                                        ).isSameOrAfter(new Date(), 'days')
                                    "
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-avatar
                                            class="cursor-pointer margin--avatar"
                                            v-on="on"
                                            color="rgb(150, 75, 132)"
                                            size="25"
                                        >
                                            <span class="white--text"
                                                >G</span
                                            ></v-avatar
                                        >
                                    </template>
                                    <span>{{ $t('devices:isWarranty') }}</span>
                                </v-tooltip>
                            </div>
                        </Separator>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3"
                        ><v-text-field
                            :value="$get(getDeviceDetails, 'model.model', '')"
                            dense
                            readonly
                            :label="$t('devices:model')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-database</v-icon
                                >
                            </template></v-text-field
                        >
                    </v-col>
                    <v-col cols="4"
                        ><v-text-field
                            dense
                            readonly
                            :value="getDeviceDetails.model.manufacturer"
                            :label="$t('devices:manufacturer')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-domain</v-icon
                                >
                            </template></v-text-field
                        >
                    </v-col>
                    <v-col cols="5"
                        ><v-text-field
                            dense
                            readonly
                            :value="getDeviceDetails.model.deviceType"
                            :label="$t('devices:deviceType')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-collage</v-icon
                                >
                            </template></v-text-field
                        >
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-textarea
                            class="ma-0"
                            dense
                            readonly
                            :value="getDeviceDetails.description"
                            rows="1"
                            no-resize
                            auto-grow
                            :label="$t('devices:description')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-text-subject</v-icon
                                >
                            </template></v-textarea
                        >
                    </v-col>
                </v-row>
                <v-row class="pb-5 mb-5">
                    <v-col cols="12" class="pb-5 mb-4"
                        ><v-text-field
                            readonly
                            dense
                            :value="getDeviceDetails.options"
                            :label="$t('devices:options')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-format-list-text</v-icon
                                >
                            </template></v-text-field
                        >
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-text-field
                            dense
                            :value="
                                $get(getDeviceDetails, 'serviceBase.field', '')
                            "
                            readonly
                            :label="$t('devices:serviceBase')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-cogs</v-icon
                                >
                            </template></v-text-field
                        >
                    </v-col>
                    <v-col cols="4"
                        ><v-text-field
                            readonly
                            dense
                            :value="
                                $get(getDeviceDetails, 'priority.field', '')
                            "
                            :label="$t('devices:priority')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-clock-fast</v-icon
                                >
                            </template></v-text-field
                        >
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3"
                        ><v-text-field
                            readonly
                            dense
                            :value="`${$get(
                                getDeviceDetails,
                                'deliverer.name',
                                `${$t('global:empty')}`
                            )} `"
                            :label="$t('devices:delivery')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-truck</v-icon
                                >
                            </template></v-text-field
                        ></v-col
                    >
                    <v-col cols="3"
                        ><v-text-field
                            readonly
                            dense
                            :value="`${$get(
                                getDeviceDetails,
                                'owner.field',
                                `${$t('global:empty')}`
                            )} `"
                            :label="$t('devices:owner')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-account-arrow-left</v-icon
                                >
                            </template></v-text-field
                        ></v-col
                    >
                    <v-col cols="3"
                        ><v-text-field
                            readonly
                            dense
                            :value="`${$get(getDeviceDetails,'purchaseInvoice', `${$t('global:empty')}`)}`"
                            :label="$t('devices:purchaseInvoice')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-receipt</v-icon
                                >
                            </template></v-text-field
                        ></v-col
                    >
                    <v-col cols="3"
                        ><v-text-field
                            dense
                            readonly
                            :value="
                                getDeviceDetails.purchaseDate
                                    ? $moment(
                                          getDeviceDetails.purchaseDate
                                      ).format(`DD-MM-YYYY`)
                                    : ''
                            "
                            :label="$t('devices:dateOfPurchase')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-calendar-today</v-icon
                                >
                            </template></v-text-field
                        >
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4"
                        ><v-text-field
                            readonly
                            dense
                            :value="
                                getDeviceDetails.guaranteeFrom
                                    ? $moment(
                                          getDeviceDetails.guaranteeFrom
                                      ).format(`DD-MM-YYYY`)
                                    : ''
                            "
                            :label="$t('devices:warrantyFrom')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-calendar-today</v-icon
                                >
                            </template></v-text-field
                        >
                    </v-col>
                    <v-col cols="4"
                        ><v-text-field
                            readonly
                            dense
                            :value="
                                getDeviceDetails.guaranteeTo
                                    ? $moment(
                                          getDeviceDetails.guaranteeTo
                                      ).format(`DD-MM-YYYY`)
                                    : ''
                            "
                            :label="$t('devices:warrantyTo')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-calendar-today</v-icon
                                >
                            </template></v-text-field
                        >
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            readonly
                            dense
                            :value="
                                getDeviceDetails.partGuarantee
                                    ? $moment(
                                          getDeviceDetails.partGuarantee
                                      ).format(`DD-MM-YYYY`)
                                    : ''
                            "
                            :label="$t('devices:warrantyParts')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-calendar-today</v-icon
                                >
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>

                <!-- <v-row>
                           
                                  <v-col cols="12" xl="7" lg="12" sm="12" class="pb-0">
                        <v-row>
                            <v-col cols="12" md="12" class="pt-0">
                                <Separator
                                    :text="$t('devices:other')"
                                /> </v-col
                        ></v-row>

                        <v-row>
                            <v-col cols="6">
                                <v-checkbox
                                    class="ma-0"
                                    readonly
                                    color="secondary"
                                    v-model="getDeviceDetails.monitoringDevice"
                                    :label="$t('devices:monitoringDevice')"
                                >
                                </v-checkbox
                            ></v-col>
                        </v-row>
                    </v-col>
                        </v-row> -->
            </v-col>
            <v-col cols="3" xl="3" lg="3" sm="12">
                <v-row>
                    <v-col cols="12" md="12" class="pt-0">
                        <Separator :text="$t('devices:client')" /> </v-col
                ></v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            dense
                            :value="$get(getDeviceDetails, 'client.name', '')"
                            :label="$t('devices:name')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-account</v-icon
                                >
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="`${$get(
                                getDeviceDetails,
                                'branch.city',
                                ''
                            )}, ${$get(
                                getDeviceDetails,
                                'branch.address',
                                ''
                            )}`"
                            dense
                            :label="$t('devices:address')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-home-city-outline</v-icon
                                > </template
                            ><template v-slot:append>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            icon
                                            @click="
                                                $emit(
                                                    'view-map',
                                                    getDeviceDetails.branch
                                                        .location
                                                )
                                            "
                                            small
                                            v-on="on"
                                        >
                                            <v-icon color="error" size="18"
                                                >mdi-map-marker</v-icon
                                            ></v-btn
                                        >
                                    </template>
                                    <span>{{ $t('devices:location') }}</span>
                                </v-tooltip>
                            </template></v-text-field
                        >
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            readonly
                            dense
                            :value="$get(getDeviceDetails, 'floor', '')"
                            :label="$t('devices:floor')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-home-floor-1</v-icon
                                >
                            </template>
                        </v-text-field>
                    </v-col></v-row
                >
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            dense
                            :value="`${$get(
                                getDeviceDetails,
                                'representatives.name',
                                'Brak'
                            )} ${$get(
                                getDeviceDetails,
                                'representatives.lastname',
                                ''
                            )} ${
                                getDeviceDetails.representatives &&
                                getDeviceDetails.representatives.email
                                    ? '•'
                                    : ''
                            } ${$get(
                                getDeviceDetails,
                                `representatives.email`,
                                ''
                            )} ${
                                getDeviceDetails.representatives &&
                                getDeviceDetails.representatives.phoneNumber
                                    ? '•'
                                    : ''
                            } ${$get(
                                getDeviceDetails,
                                'representatives.phoneNumber',
                                ''
                            )}`"
                            :label="$t('devices:contactPerson')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-phone</v-icon
                                >
                            </template></v-text-field
                        >
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="comment-margin">
                        <v-textarea
                            readonly
                            dense
                            rows="1"
                            no-resize
                            :value="getDeviceDetails.comment"
                            auto-grow
                            :label="$t('devices:comments')"
                            ><template v-slot:prepend>
                                <v-icon color="iconGray" size="18"
                                    >mdi-alert-octagon</v-icon
                                >
                            </template></v-textarea
                        >
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            checkboxValue: true,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' PLN',
                precision: 2,
                masked: false,
            },
        }
    },
    i18nOptions: { namespaces: ['devices'] },
    computed: {
        ...mapGetters(['getDeviceDetails', 'getProfileDetails']),
    },
    methods: {
        openMap(location) {
            this.map.coords = location
            this.map.open = true
        },

        checkPermissions() {
            return (
                this.getProfileDetails.role.registries
                    .find(e => e.url === 'leasings')
                    .operations.includes('READ') ||
                this.getProfileDetails.isAdmin ||
                this.getProfileDetails.isSuperAdmin
            )
        },
    },
}
</script>
<style lang="sass" scoped>
.margin--avatar
    margin-top: -5px
    margin-left: 5px
.weight--700
    font-weight: 700
.input--sn > .v-input__control
    background-color: yellow !important
.comment-margin
    margin-top: 60px
.margin--input
    margin-top: 9px !important
.dedicated--prices
    justify-content: space-between
    font-size: 14px !important
    height: 224px
    margin-left: -25px
    margin-top: 78px
    color: #9e9e9e !important
.dedicated--prices span
    margin-bottom: 5px
.summaryLine--font
    font-size: 14px !important
.money-value
    padding: 5px 0px
    font-size: 14px !important
    color: #9e9e9e !important
</style>
<style lang="sass">
.input--sn > .v-input__control > .v-input__slot > .v-text-field__slot > input
    background-color: #ffff86 !important
    font-size: 17px !important
    padding-left: 7px !important
.v-textarea textarea
    min-height: 22px !important
</style>
