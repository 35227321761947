var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () =>
                                    _vm.getImplementationError.stepOne
                                        .validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('devices:basicInformations')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('devices:basicInformations')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-textarea',{attrs:{"auto-grow":"","rows":"1","label":_vm.$t('devices:data')},model:{value:(
                                            _vm.getImplementationModal.data
                                        ),callback:function ($$v) {_vm.$set(_vm.getImplementationModal, "data", $$v)},expression:"\n                                            getImplementationModal.data\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-textarea',{attrs:{"rows":"1","auto-grow":"","label":_vm.$t('devices:settings')},model:{value:(
                                            _vm.getImplementationModal.settings
                                        ),callback:function ($$v) {_vm.$set(_vm.getImplementationModal, "settings", $$v)},expression:"\n                                            getImplementationModal.settings\n                                        "}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }