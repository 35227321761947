var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('TableDetails',{attrs:{"headers":_vm.headers,"page":_vm.getArchivesTable.page,"items":_vm.getArchivesTable.items,"length":_vm.getArchivesTable.pages},on:{"sort":_vm.setSort,"search":_vm.search,"change-page":_vm.setPage}},[_c('Columns',{attrs:{"slot":"columns","items":_vm.getArchivesTable.items},slot:"columns"})],1),_c('div',{staticClass:"d-flex summary--cost flex-column"},[_c('span',[_c('span',{staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(`Koszty ostatniego klienta Usługi: ${_vm.$get( _vm.getServiceTime(), 'h', '0' )} h - `)+" "),(_vm.total)?_c('div',[(
                            _vm.getServiceTime().total ===
                            _vm.$t('service-prices:noOwnManHoursCostSet')
                        )?_c('div',{staticClass:"no-ownManHoursCost",staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(` ${_vm.$get(_vm.getServiceTime(), 'total', '0')} `)+" ")]):_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(_vm.getServiceTime().total)+" ")])]):_vm._e()]),_c('span',{staticStyle:{}},[_vm._v(" zł; Części: ")]),_c('span',[_vm._v(_vm._s(_vm.setPartsSum().toFixed(2).replace('.', ','))+" zł + VAT")])]),_c('span',[_c('span',{staticStyle:{}},[_vm._v(" "+_vm._s(`Koszty całkowite: Usługi: ${_vm.$get( _vm.setTimeSum(), 'hours', '' )} ${_vm.$t('devices:h')} - `)+" "),(
                        _vm.setTimeSum().total ===
                        _vm.$t('service-prices:noOwnManHoursCostSet')
                    )?_c('div',{staticStyle:{"display":"inline-block"}},[_c('span',{staticClass:"no-ownManHoursCost"},[_vm._v(" "+_vm._s(` ${_vm.$get(_vm.setTimeSum(), 'total', '0')} `)+" ")])]):_c('div',{staticStyle:{"display":"inline-block"}},[_c('span',{staticStyle:{}},[_vm._v(" "+_vm._s(` ${_vm.$get(_vm.setTimeSum(), 'total', '0')} `)+" ")])])]),_c('span',[_vm._v(" zł; Części: ")]),_c('span',[_vm._v(_vm._s(_vm.setPartsSum().toFixed(2).replace('.', ',')))]),_c('span',{staticClass:"text"},[_vm._v(" zł + VAT")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }