<template>
    <div>
        <v-btn @click="edit()" class="buttons buttons--add">
            {{ $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },

    methods: {
        ...mapActions(['anotherUpdate']),
        edit() {
            this.anotherUpdate({ id: this.$route.params.id }).then(() =>
                this.$emit('close-modal')
            )
        },
    },
}
</script>
