<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <td class="truncate">
                <span>{{ item.index }}</span>
            </td>
            <td class="truncate">
                <DoubleLine
                    :firtstLine="$moment(item.createdAt).format('YYYY-MM-DD')"
                    :secondLine="$moment(item.createdAt).format('HH:mm')"
                />
            </td>
            <td class="truncate">
                <span>{{
                    `${$get(item, 'technician.name', '')} ${$get(
                        item,
                        'technician.lastname',
                        ''
                    )}`
                }}</span>
            </td>  
            <td class="truncate">
                <span>{{ item.realization.interventionRange }}</span>
            </td>
            <td class="truncate">
                <span>{{ item.realization.technicianComments }}</span>
            </td>
            <td class="truncate">
                <DoubleLine
                    :firtstLine="`Usługa: 
                    Godz: ${getServiceTime(item.realization).h}
                    , Min: ${getServiceTime(item.realization).m}`"
                    :secondLine="`Części: ${setPrice(item.parts)} PLN`"
                />
            </td>
            <td style="text-align: end;">
                <v-icon small v-if="hasFile(item.folders)"
                    >mdi-paperclip</v-icon
                >
                <v-btn small router :to="`/service/${item._id}`" icon>
                    <v-icon color="secondary" small
                        >mdi-arrow-right-thick</v-icon
                    >
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>
<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
export default {
    props: ['items'],
    methods: {
        setPrice(parts) {
            if (!parts?.sum) return '0'
            return parts.sum.toString()
        },
        getServiceTime(realization) {
            const { startTime, startDate, endTime, endDate } = realization

            if (!endDate || !startDate || !endTime || !startTime)
                return { h: '--', m: '--'}

            let end = moment(
                `${moment(endDate).format('YYYY-MM-DD')}T${endTime}:00`
            )
            let start = moment(
                `${moment(startDate).format('YYYY-MM-DD')}T${startTime}:00`
            )
            let result = moment.duration(end.diff(start))
    
            return {
                h: result._data.hours + result._data.days * 24,
                m: result._data.minutes,
                d: result._data.days,
            }
        },
        edit() {
            return this.checkPermissions('UPDATE')
        },
    },
}
</script>
