<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step editable step="1">{{
                                $t('devices:qrCodeDevices')
                            }}</v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="$t('devices:qrCodeDevices')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        md="12"
                                        class="d-flex flex-column align-center"
                                    >
                                        <qrcode-vue :value="sn" :size="200" />
                                        <span id="SN">S/N: {{ sn }}</span>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        sn: {
            type: String,
        },
    },
    data() {
        return {
            date: '',
            step: 1,
            editable: true,
            value: '5861FSD',
            size: 300,
        }
    },
    components: {
        QrcodeVue,
    },
    methods: {},
    computed: {
        ...mapGetters([]),
    },
}
</script>
